import React from "react";
import BaseLayout from "../components/BaseLayout";
import { Widget } from "@typeform/embed-react";

import { StyledPre } from "../styles/StyledPre";

function Pre({ pageContext: { fechedOfferList } }) {
  return (
    <BaseLayout destailPage={true} footerMap={false} footerAdress={false}>
      <StyledPre>
        <Widget id="juQ3b7he" style={{ width: "100%" }} className="stasForm" />
      </StyledPre>
    </BaseLayout>
  );
}

export default Pre;
